import { emitHttpRegisterForTournament } from '@features/tournaments/store/tournaments.actions';
import { parseError } from '@http/httpHelpers';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import IconCheckSign from './IconCheckSign';
import IconCloseModal from './IconCloseModal';
import IconUnlock from './IconUnlock';
import InputText from './InputText';
import SecondaryButton from './SecondaryButton';
import { showToast } from '../store/common.reducer';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

const modalAlert = withReactContent(Swal);

interface IUnlockRegistrationModal {
	code: string;
}

interface IRegistrationTournamentModalProps {
	onClose: () => void;
	tournamentId: string;
	tournamentName: string;
}

interface IShowRegistrationModalProps extends IRegistrationTournamentModalProps {
	dispatch: ReturnType<typeof useAppDispatch>;
	formatMessage: ReturnType<typeof useIntl>['formatMessage'];
}

const ShowRegistrationModal = (props: IShowRegistrationModalProps) => {
	const { onClose, dispatch, formatMessage, tournamentId, tournamentName } = props;
	const { handleSubmit, formState, register, setError, clearErrors, reset } = useForm<IUnlockRegistrationModal>({
		mode: 'onChange',
	});

	const [isLoading, setIsLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const closeModalIconKeys = useRef({ static: nanoid(), animated: nanoid() });

	const onSubmitForm = (formValue: IUnlockRegistrationModal) => {
		let message: string;
		let button: string;

		setIsLoading(true);
		dispatch(
			emitHttpRegisterForTournament({
				tournamentId: tournamentId,
				accessCode: formValue.code,
				terms: true,
			})
		)
			.then(unwrapResult)
			.then(() => {
				setShowSuccess(true);
			})
			.catch((error: any) => {
				const parsedError = parseError(error);
				switch (parsedError.code) {
					case 'INVALID_FIELDS':
						setError('code', {
							message: formatMessage({
								id: 'bcs-Error-InvalidCode',
							}),
						});
						break;
					case 'NO_PERMISSION':
						message = formatMessage({
							id: 'bcs-Registration-No-Permission',
						});
						button = formatMessage({
							id: 'bcs-Common-GotIt',
						});

						dispatch(
							showToast({
								message,
								type: 'warning',
								title: formatMessage({ id: 'bcs-Common-Oops' }),
								buttonText: button ? button : '',
							})
						);
						break;
					case 'PLAY_LIMIT_REACHED':
						message = formatMessage({
							id: 'bcs-Championship-With-Awards-Plays-Limit-Error-Handler',
						});
						button = formatMessage({
							id: 'bcs-Common-GotIt',
						});

						dispatch(
							showToast({
								message,
								type: 'warning',
								title: formatMessage({ id: 'bcs-Common-Oops' }),
								buttonText: button ? button : '',
							})
						);
						break;
					default:
						break;
				}
			})
			.finally(() => {
				setIsLoading(false);
				onClose();
			});
	};

	const onCloseClick = () => {
		onClose();
		reset();
		clearErrors();
		modalAlert.close();
	};

	return (
		<>
			<div className='flex justify-between items-center' data-testid='registrationTournamentModal'>
				<p
					style={{
						fontFamily: 'Montserrat',
						fontWeight: '600',
						fontSize: '26px',
						lineHeight: '25px',
						color: '#1B1D21',
					}}
				>
					{formatMessage({ id: 'bcs-Tournament-Register-Enter' })}
				</p>

				<button type='button' data-testid='closeButtonRegistrationModal' onClick={onCloseClick}>
					{showSuccess ? (
						<IconCloseModal
							key={closeModalIconKeys.current.animated}
							animate={true}
							onAnimationEnd={onCloseClick}
						/>
					) : (
						<IconCloseModal key={closeModalIconKeys.current.static} />
					)}
				</button>
			</div>
			{showSuccess ? (
				<div className='flex justify-center items-center w-full flex-col mt-[13px]'>
					<IconCheckSign />
					<p className='mt-4 mb-[105px] text-lg leading-[1.563rem] text-center text-dark-600 font-medium'>
						{formatMessage({ id: 'bcs-Tournament-Successful-Registration' })}
					</p>
				</div>
			) : (
				<>
					<div className='flex justify-center' style={{ marginTop: '22px' }}>
						<IconUnlock />
					</div>
					<p className='font-medium' style={{ marginTop: '17px', marginBottom: '20px', color: '#1B1D21' }}>
						{formatMessage(
							{ id: 'bcs-Tournament-Register-Description' },
							{ tournamentName: tournamentName, br: <br /> }
						)}
					</p>
					<form onSubmit={handleSubmit(onSubmitForm)} data-testid='form'>
						<div className='flex justify-center text-left'>
							<InputText
								id={'code'}
								type={'text'}
								testId={'code'}
								tabIndex={1}
								label={''}
								limitLength={10}
								fieldError={formState.errors.code}
								placeholder={formatMessage({ id: 'bcs-Tournament-Register-Code' })}
								hookRegister={register('code', {
									required: {
										value: true,
										message: formatMessage({
											id: 'bcs-Error-EmptyCode',
										}),
									},
								})}
							/>
						</div>
						<div className='flex justify-center space-x-3 s:mt-[10px] mt-[20px]'>
							<div
								style={{
									minWidth: '117px',
									height: '56px',
									color: '#11142D',
									lineHeight: '20px',
									fontSize: '15px',
								}}
							>
								<button
									className={classNames(
										'w-full min-w-max bg-main-500 disabled:bg-disabledMain-500 border border-transparent pointer-events-auto disabled:pointer-events-none text-base text-white font-bold rounded-2xl focus:outline-none outline-none transition-all',
										{
											'hover:bg-main-600': !isMobile,
											'active:bg-main-500': true,
										}
									)}
									type='submit'
									disabled={!!formState.errors.code || isLoading}
									tabIndex={2}
									style={{ padding: '15px 14px', fontFamily: "'Inter', sans-serif" }}
								>
									{formatMessage({ id: 'bcs-Tournaments-ListingRegister' })}
								</button>
							</div>

							<div
								style={{ width: '107px', height: '56px' }}
								data-testid='closeButtonRegistrationFormModal'
								onClick={onCloseClick}
							>
								<SecondaryButton
									type='button'
									text={formatMessage({ id: 'bcs-Common-Cancel' })}
									tabIndex={3}
								/>
							</div>
						</div>
					</form>
				</>
			)}
		</>
	);
};

const RegistrationTournamentModal = (props: IRegistrationTournamentModalProps) => {
	const { onClose, tournamentId, tournamentName } = props;
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();
	const isModalRenderedRef = useRef(false);

	const showModal = useCallback(() => {
		modalAlert.fire({
			html: (
				<ShowRegistrationModal
					dispatch={dispatch}
					formatMessage={formatMessage}
					onClose={onClose}
					tournamentId={tournamentId}
					tournamentName={tournamentName}
				/>
			),
			showCancelButton: false,
			showCloseButton: false,
			showDenyButton: false,
			heightAuto: false,
			timer: undefined,
			position: 'center',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			width: '684px',
			customClass: {
				popup: 'alert-border-radius',
			},
		});
	}, [dispatch, formatMessage, onClose, tournamentId, tournamentName]);

	useEffect(() => {
		if (!isModalRenderedRef.current) {
			isModalRenderedRef.current = true;
			showModal();
		}
	}, [showModal]);

	return null;
};

export default RegistrationTournamentModal;
