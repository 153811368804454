import React from 'react';

const IconUnlock = () => {
	return (
		<svg width='84' height='107' viewBox='0 0 84 107' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M73.5 97.5595H74.25V96.8095V45.8571V45.1071H73.5H10.5H9.75V45.8571V96.8095V97.5595H10.5H73.5ZM57.75 36.4167H58.5V35.6667V25.4762C58.5 21.2159 56.756 17.1348 53.6593 14.1294C50.5633 11.1247 46.3689 9.44048 42 9.44048C37.6311 9.44048 33.4367 11.1247 30.3407 14.1294C27.4262 16.9579 25.7099 20.7393 25.518 24.7262H16.5117C16.708 18.4475 19.3637 12.4616 23.9608 8.00001C28.7414 3.36036 35.2299 0.75 42 0.75C45.3518 0.75 48.6702 1.39075 51.7657 2.63514C54.8611 3.87951 57.6723 5.70286 60.0392 8.00001C62.4061 10.2971 64.2824 13.0229 65.562 16.0213C66.8417 19.0196 67.5 22.2323 67.5 25.4762V35.6667V36.4167H68.25H73.5C76.093 36.4167 78.5754 37.4166 80.4023 39.1896C82.2284 40.9619 83.25 43.3607 83.25 45.8571V96.8095C83.25 99.3059 82.2284 101.705 80.4023 103.477C78.5754 105.25 76.093 106.25 73.5 106.25H10.5C5.06788 106.25 0.75 101.981 0.75 96.8095V45.8571C0.75 43.3607 1.77161 40.9619 3.59772 39.1896C5.42459 37.4166 7.90702 36.4167 10.5 36.4167H57.75ZM42 80.7738C39.407 80.7738 36.9246 79.7739 35.0977 78.0009C33.2716 76.2286 32.25 73.8297 32.25 71.3333C32.25 68.8369 33.2716 66.4381 35.0977 64.6658C36.9246 62.8928 39.407 61.8929 42 61.8929C44.593 61.8929 47.0754 62.8928 48.9023 64.6658C50.7284 66.4381 51.75 68.8369 51.75 71.3333C51.75 73.8297 50.7284 76.2286 48.9023 78.0009C47.0754 79.7739 44.593 80.7738 42 80.7738Z'
				fill='#FF652E'
				stroke='white'
				strokeWidth='1.5'
			/>
		</svg>
	);
};

export default IconUnlock;
