import React from 'react';

interface IInformationIconButtonProps {
	color: string;
	width: number;
	height: number;
}

const InformationIconButton = ({ color, height, width }: IInformationIconButtonProps) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 19 18' fill='none'>
			<path
				d='M9.76172 18C14.7117 18 18.7617 13.95 18.7617 9C18.7617 4.05 14.7117 0 9.76172 0C4.81172 0 0.761719 4.05 0.761719 9C0.761719 13.95 4.81172 18 9.76172 18ZM10.0317 4.761C10.2117 4.599 10.4277 4.5 10.6617 4.5C10.9047 4.5 11.1117 4.599 11.3007 4.761C11.4717 4.95 11.5617 5.166 11.5617 5.4C11.5617 5.643 11.4717 5.85 11.3007 6.039C11.1117 6.21 10.9047 6.3 10.6617 6.3C10.4277 6.3 10.2117 6.21 10.0317 6.039C9.86072 5.85 9.76172 5.643 9.76172 5.4C9.76172 5.166 9.86072 4.95 10.0317 4.761ZM7.78172 8.973C7.78172 8.973 9.73472 7.425 10.4457 7.362C11.1117 7.308 10.9767 8.073 10.9137 8.469L10.9047 8.523C10.7787 9 10.6257 9.576 10.4727 10.125C10.1307 11.376 9.79772 12.6 9.87872 12.825C9.96872 13.131 10.5267 12.744 10.9317 12.474C10.9857 12.438 11.0307 12.402 11.0757 12.375C11.0757 12.375 11.1477 12.303 11.2197 12.402C11.2377 12.429 11.2557 12.456 11.2737 12.474C11.3547 12.6 11.3997 12.645 11.2917 12.717L11.2557 12.735C11.0577 12.87 10.2117 13.464 9.86972 13.68C9.50072 13.923 8.08772 14.733 8.30372 13.158C8.49272 12.051 8.74472 11.097 8.94272 10.35C9.31172 9 9.47372 8.388 8.64572 8.919C8.31272 9.117 8.11472 9.243 7.99772 9.324C7.89872 9.396 7.88972 9.396 7.82672 9.279L7.79972 9.225L7.75472 9.153C7.69172 9.063 7.69172 9.054 7.78172 8.973Z'
				fill={color}
			/>
		</svg>
	);
};

export default InformationIconButton;
