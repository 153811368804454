import useOnClickOutside from '@hooks/useClickOutside';
import classNames from 'classnames';
import { Console } from 'console';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { fromEvent, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

type pageViewType = 'Homepage' | 'Organize' | 'PlayNowChampionshipWithAwards' | 'Other';

interface CommonTooltipProps {
	children: ReactNode;
	text: string;
	type: 'contained' | 'max-content';
	className?: string;
	pageView?: pageViewType;
}

const CommonTooltip = (props: CommonTooltipProps) => {
	const { children, text, className, type, pageView } = props;
	const [tooltipElement, setTooltipElement] = useState<HTMLSpanElement | null>(null);
	const childrenContainerRef = useRef<HTMLDivElement>(null);
	const [showTooltip, setShowTooltip] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
	const [containerChildrenSize, setContainerChildrenSize] = useState({ width: 0, height: 0 });

	useOnClickOutside({ current: tooltipElement }, () => setShowTooltip(false));

	useEffect(() => {
		if (tooltipElement) {
			const { height, width } = tooltipElement.getBoundingClientRect();
			const leftCorrection = width / 3;

			setTooltipPosition(({ top, left }) => {
				const newTopResult = top - height < 0 ? top + containerChildrenSize.height : top - height;

				let newLeftResult;

				if (width > 100) {
					newLeftResult = left;
				} else {
					if (left - leftCorrection < 0) {
						newLeftResult = left + containerChildrenSize.width;
					} else {
						newLeftResult = left - leftCorrection;
					}
				}

				return {
					top: newTopResult,
					left: newLeftResult,
				};
			});
		}
	}, [containerChildrenSize, tooltipElement]);

	useEffect(() => {
		let subscription: Subscription;
		if (showTooltip) {
			subscription = fromEvent(document, 'scroll', { capture: true })
				.pipe(
					take(1),
					tap(() => setShowTooltip(false))
				)
				.subscribe();
		}

		return () => {
			subscription?.unsubscribe();
		};
	}, [showTooltip]);

	const onClickContainer = () => {
		if (childrenContainerRef.current) {
			const { top, left, height, width } = childrenContainerRef.current?.getBoundingClientRect();

			setTooltipPosition({ top, left });
			setContainerChildrenSize({ width, height });
		}

		setShowTooltip(true);
	};

	return (
		<>
			<div
				className={classNames('md:cursor-pointer', { [`${className}`]: !!className })}
				onClick={onClickContainer}
				ref={childrenContainerRef}
				data-testid='commonTooltipContainer'
			>
				{children}
			</div>
			{createPortal(
				<AnimatePresence mode='wait'>
					{showTooltip ? (
						<motion.span
							key='mobileTooltip'
							initial={{ opacity: 0, dur: 300 }}
							animate={{ opacity: 1, dur: 300 }}
							exit={{ opacity: 0, dur: 300 }}
							className={classNames(
								'fixed rounded-md p-3 bg-dark-700 shadow-lg z-50 text-white text-xs',
								{
									'xl:w-[20%] lg:w-[25%] s:w-[45%] xs:w-[31%] w-[37%] font-medium':
										type === 'contained' && pageView === 'PlayNowChampionshipWithAwards',
									'xl:w-[20%] lg:w-[25%] font-medium':
										type === 'contained' && (pageView === 'Organize' || pageView === 'Other'),
									'2xl:w-[10%] xl:w-[20%] lg:w-[15%] font-medium':
										type === 'contained' && pageView !== 'Homepage' && pageView !== 'Organize',
									'font-semibold uppercase': type === 'max-content',
									'2xl:w-[20%] lg:w-[30%] md:w-[50%] sm:w-[60%] font-medium':
										type === 'contained' && pageView === 'Homepage',
								}
							)}
							ref={setTooltipElement}
							style={{ ...tooltipPosition, fontFamily: "'Inter', sans-serif" }}
							data-testid='tooltipText'
						>
							{text}
						</motion.span>
					) : null}
				</AnimatePresence>,
				document.body
			)}
		</>
	);
};

export default CommonTooltip;
