import React from 'react';
import classNames from 'classnames/dedupe';

type IIconClockTypes = 'TOURNAMENT_BOX' | 'TOURNAMENT_VIEW';

interface IIconClockProps {
	diameter?: string;
	view?: IIconClockTypes;
}

const IconClock = ({ diameter, view }: IIconClockProps) => {
	return (
		<>
			<svg
				className={classNames('', {
					'xs:flex hidden sm:min-w-[1.6875rem] sm:min-h-[1.6875rem] min-w-[1.1875rem] min-h-[1.1875rem]':
						view === 'TOURNAMENT_BOX',
				})}
				width={`${diameter ? diameter : '27'}`}
				height={`${diameter ? diameter : '27'}`}
				viewBox={`0 0 27 27`}
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					className='fill-current hover:fill-current'
					d='M13.332 0.753906C10.7365 0.753906 8.19929 1.52355 6.04123 2.96552C3.88317 4.40749 2.20116 6.45702 1.20792 8.85493C0.21467 11.2528 -0.0452081 13.8914 0.461144 16.437C0.967497 18.9826 2.21734 21.3209 4.05262 23.1562C5.8879 24.9915 8.22619 26.2413 10.7718 26.7477C13.3174 27.254 15.956 26.9942 18.3539 26.0009C20.7518 25.0077 22.8013 23.3257 24.2433 21.1676C25.6853 19.0095 26.4549 16.4723 26.4549 13.8769C26.4512 10.3976 25.0674 7.06191 22.6071 4.60169C20.1469 2.14147 16.8112 0.757669 13.332 0.753906V0.753906ZM19.7371 17.7208C19.5814 17.9622 19.3396 18.1353 19.0609 18.2047C18.7822 18.2742 18.4874 18.2349 18.2367 18.0948L12.7688 14.8141C12.6121 14.7113 12.4823 14.5727 12.3901 14.4097C12.2978 14.2467 12.2458 14.064 12.2384 13.8769V7.31539C12.2384 7.02535 12.3536 6.7472 12.5587 6.54211C12.7638 6.33702 13.0419 6.22181 13.332 6.22181C13.622 6.22181 13.9001 6.33702 14.1052 6.54211C14.3103 6.7472 14.4255 7.02535 14.4255 7.31539V13.2579L19.3631 16.2204C19.6116 16.3698 19.7906 16.6118 19.8607 16.8932C19.9309 17.1745 19.8864 17.4722 19.7371 17.7208Z'
					fill='#808191'
				/>
			</svg>
		</>
	);
};

export default IconClock;
